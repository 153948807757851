export enum MainPath {
  HOME = "home",
  MANUFACTURER = "manufacturer",
  PARTS = "parts",
  USERS = "users",
  REQUEST_PLATFORM = "requestplatform",
  CALLBACK = "callback",
  CALLBACK_SILENT = "signin-silent-callback.html",
  CALLBACK_SIGNOUT = "callback_signout",
  CALLBACK_POPUP = "callback_popup",
  NOT_AUTHORIZED = "not-authorized",
}
