import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthorizationService } from "./authorization.service";
import { map, Observable, tap } from "rxjs";

export const NoAuthGuard: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => Observable<boolean> = (): Observable<boolean> => {
  const authService = inject(AuthorizationService);
  const router = inject(Router);

  return authService.isUserAuthorized$.pipe(
    tap(isUserAuthorized => {
      if (isUserAuthorized) {
        router.navigateByUrl("/")
      }
    }),
    map(isUserAuthorized => !isUserAuthorized)
  );
}
