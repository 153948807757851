import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SigninCallbackComponent, SigninPopupCallbackComponent, SignoutCallbackComponent } from "@eplan/auth";
import { NotAuthorizedComponent } from "app/shared/components/not-authorized/not-authorized.component";
import { AuthGuard } from "app/services/auth/auth-guard";
import { ShellComponent } from "./shared/components/router/shell/shell.component";
import { Blade } from "./models/blade";
import { environment } from "environments/environment";
import { RequestPlatformComponent } from "./components/request-platform/request-platform.component";
import { MainPath } from "./models/app.routes.model";
import { NoAuthGuard } from "./services/auth/no-auth-guard";

const appRoutes: Routes = [
  { path: "", redirectTo: MainPath.HOME, pathMatch: "full" },
  { path: MainPath.HOME, component: ShellComponent, canActivate: [AuthGuard], data: { target: Blade.Home } },
  { path: MainPath.MANUFACTURER, component: ShellComponent, canActivate: [AuthGuard], data: { target: Blade.Manufacturer } },
  { path: MainPath.PARTS, component: ShellComponent, canActivate: [AuthGuard], data: { target: Blade.Parts } },
  { path: MainPath.USERS, component: ShellComponent, canActivate: [AuthGuard], data: { target: Blade.Users } },
  { path: MainPath.REQUEST_PLATFORM, component: RequestPlatformComponent, canActivate: [AuthGuard] },
  { path: MainPath.CALLBACK, component: SigninCallbackComponent },
  { path: MainPath.CALLBACK_SIGNOUT, component: SignoutCallbackComponent },
  { path: MainPath.CALLBACK_POPUP, component: SigninPopupCallbackComponent },
  { path: MainPath.NOT_AUTHORIZED, component: NotAuthorizedComponent, canActivate: [NoAuthGuard] },
  { path: "**", redirectTo: MainPath.HOME }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes,
    { useHash: false, enableTracing: environment.debug.routerTracing })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
