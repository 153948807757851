import { EplanAuthSettings, EplanOidcClientSettings, OidcLoggingSettings } from "@eplan/auth";
import { Log } from "oidc-client-ts";
import { MainPath } from "app/models/app.routes.model";
import { EplanWindow } from "app/models/external-libs/eplan-window";

const eplanWindow = window as EplanWindow;

/** Gets client configuration for oidc client (required) */
export const oidcClientSettings: EplanOidcClientSettings = {
  client_id: eplanWindow.configuration.eplanAuthService.clientId,
  redirect_uri: `${window.location.origin}/${MainPath.CALLBACK}`,
  silent_redirect_uri: `${window.location.origin}/${MainPath.CALLBACK_SILENT}`,
  post_logout_redirect_uri: `${window.location.origin}/${MainPath.CALLBACK_SIGNOUT}`,
  automaticSilentRenew: true,
  silentRequestTimeoutInSeconds: 60,
  accessTokenExpiringNotificationTimeInSeconds: 120,
  response_type: "code",
  scope: eplanWindow.configuration.eplanAuthService.scope,
  includeIdTokenInSilentRenew: true,
  monitorSession: true,
};

/** Sets internal auth package settings. */
export const eplanAuthSettings: EplanAuthSettings = {
  performRedirectToAppsPageOnNoRights: false,
};

/** Sets internal auth package settings. */
export const oidcLoggerSettings: OidcLoggingSettings = {
  enableOidcLogging: false,
  oidcLogLevel: Log.INFO,
  enableEplanAuthLogging: false,
};
