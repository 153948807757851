import { Inject, Injectable, OnDestroy } from "@angular/core";
import { AUTH_SERVICE, AuthService, UserData } from "@eplan/auth";
import { BehaviorSubject, combineLatest, filter, map, Observable, Subscription } from "rxjs";
import { EplanWindow } from "app/models/external-libs/eplan-window";
import { SignoutRedirectArgs } from "oidc-client-ts";
import { MainPath } from "app/models/app.routes.model";
import { Router } from "@angular/router";

@Injectable()
export class AuthorizationService implements OnDestroy {
  isUserAuthorized$: Observable<boolean> = combineLatest([this.authService.signedIn$, this.authService.isAuthorized(this.getRightToken("CanReadData"))])
    .pipe(
      filter(([signedIn]) => !!signedIn),
      map(([, isAuth]) => isAuth),
    );
  userData$: BehaviorSubject<UserData | null> = new BehaviorSubject<UserData | null>(null);
  private subscriptions = new Subscription();
  private signingOut = false;

  public constructor(
    @Inject(AUTH_SERVICE) private authService: AuthService,
    private router: Router,
  ) {
    this.subscriptions.add(
      this.authService.userData$.subscribe(userData => this.userData$.next(userData))
    );

    this.subscriptions.add(
      this.isUserAuthorized$.subscribe(isUserAuthorized => {
        if (isUserAuthorized) {
          const currentUrl = this.router.lastSuccessfulNavigation?.finalUrl?.toString();
          const previousUrl = this.router.lastSuccessfulNavigation?.previousNavigation?.finalUrl?.toString();
          if (currentUrl === `/${MainPath.NOT_AUTHORIZED}`) {
            router.navigateByUrl(previousUrl || "/");
          }
        } else {
          router.navigateByUrl(MainPath.NOT_AUTHORIZED);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getRightToken(right: string): string {
    return (window as EplanWindow).configuration.eplanAuthService.clientId + "." + right;
  }

  signout(args?: SignoutRedirectArgs) {
    if (!this.signingOut) {
      this.signingOut = true;
      this.authService.signout(args);
    }
  }
}
