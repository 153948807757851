import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { MainPath } from "app/models/app.routes.model";
import { AuthorizationService } from "./authorization.service";
import { Observable, tap } from "rxjs";

export const AuthGuard: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => Observable<boolean> = (): Observable<boolean> => {
  const authService = inject(AuthorizationService);
  const router = inject(Router);

  return authService.isUserAuthorized$.pipe(
    tap(isUserAuthorized => {
      if (!isUserAuthorized) {
        router.navigateByUrl(MainPath.NOT_AUTHORIZED);
      }
    })
  );
}
